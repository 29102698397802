import { configureStore } from '@reduxjs/toolkit';
import userDataReducer from './slices/userDataSlice';
import currentLoginFlowPopupReducer from './slices/currentLoginFlowPopupSlice';
import addressFlowPopupReducer from './slices/addressFlowPopupSlice';
import popupModalReducer from './slices/popupModal';
import cartQuantityReducer from './slices/updateCartQuantitySlice';
import cartDataReducer from './slices/updateCurrentCartDataSlice';
import productRemovedFromCartReducer from './slices/productRemovedFromCart';

const store = configureStore({
  reducer: {
    userData: userDataReducer,
    popupModal:popupModalReducer,
    cartQuantity: cartQuantityReducer,
    cartData: cartDataReducer,
    productRemovedFromCart: productRemovedFromCartReducer
/*     currentLoginFlowPopup: currentLoginFlowPopupReducer,
    addressFlowPopup: addressFlowPopupReducer */
  },
});

export default store;
