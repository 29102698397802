import { toast } from 'react-hot-toast';

const useCustomToast = () => {
  const showToast = (type, message,  duration = 500) => {
    const className = {
      success: 'bg-[#e9ffee] border-s-4 border-[#28a745] text-[#28a745]',
      error: 'bg-[#ffe9eb] border-s-4 border-[#dc3545] text-[#dc3545]',
      warning: 'bg-[#fffaea] border-s-4 border-[#ffc107] text-[#ffc107]',
      info: 'bg-[#dbfaff] border-s-4 border-[#17a2b8] text-[#17a2b8]',
    };
    const icons = {
      success: <img src="/assets/icons/toast-success.svg" alt="Success" className="size-5" />,
      error: <img src="/assets/icons/toast-error.svg" alt="Error" className="size-5" />,
      warning: <img src="/assets/icons/toast-warning.svg" alt="Warning" className="size-5" />,
      info: <img src="/assets/icons/toast-info.svg" alt="Info" className="size-5" />,
    };

    toast.custom((t) => (
      <div
        onClick={() => toast.dismiss(t.id)}
        className={`${
          t.visible ? 'animate-enter' : 'animate-leave'
        } max-w-xs w-full ${className[type]} p-4 rounded-lg shadow-lg cursor-pointer`} 
      >
        <div className="flex gap-2 items-center">
          {icons[type]}
          <p className="text-sm">{message}</p>
        </div>
      </div>
      
    ), { duration });
  };

  return { showToast };
};

export default useCustomToast;
