import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeFromCart, updateFromCart } from '../utility-functions/addToCartHelper.js';
import { setCartData } from '../redux/slices/updateCurrentCartDataSlice.js';
import useCustomToast from '../hooks/useCustomToast.jsx';
import { setPopupStatus, setPopupType } from '../redux/slices/popupModal.js';
import { formatPrice } from '../utility-functions/formatters.js';
import { setProductWhichIsRemoved } from '../redux/slices/productRemovedFromCart.js';

function MiniCart() {
    const [cartMenu, setCartMenu] = useState(false);
    const [displayDeletePopup, setDisplayDeletePopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const currentCartQuantity = useSelector((state) => state.cartQuantity.quantity, shallowEqual);    
    const currentCartData = useSelector((state) => state.cartData.data, shallowEqual);    
    const [miniCartData, setMiniCartData] = useState([]);
    const [currentCartTotal, setCurrentCartTotal] = useState(0);
    const userData = useSelector((state) => state?.userData?.data, shallowEqual);
    const [isQuantityLoading, setQuantityLoading] = useState(false);
    const { showToast } = useCustomToast();
    const dispatch = useDispatch()

    useEffect(() => {
        if (currentCartData && Array.isArray(currentCartData) && currentCartData.length > 0) { 
            const totalFinalPrice = currentCartData.reduce((total, item) => total + parseFloat(item.final_price) * item.quantity, 0);
            const formattedTotalFinalPrice = formatPrice(totalFinalPrice);
            setCurrentCartTotal(formattedTotalFinalPrice)          
            setMiniCartData(currentCartData);
        } else {
            dispatch(setCartData([]))
            setMiniCartData([]);
            console.warn('Cart is empty or invalid!');
        }
    
        return () => {
            // Cleanup if necessary
        };
    }, [currentCartData]);
    

    const handleQuantityChange = (item, amount) => {
        const newQuantity = Math.max(1, Number(item.quantity) + amount);
      
        if (currentCartData && currentCartData.length > 0) {
          // Update cart data locally
          const updatedCartData = currentCartData.map(cartItem =>
            Number(cartItem.id) === Number(item.id)
              ? { ...cartItem, quantity: newQuantity }
              : cartItem
          );
      
          dispatch(setCartData(updatedCartData));
          const updatedItem = { ...item, quantity: newQuantity };
          setQuantityLoading(true);
          updateFromCart(updatedItem, userData, showToast)
            .then((res) => {
              console.log(res, "response");
            })
            .catch((err) => {
              console.error(err.message || "Error updating cart.");
            })
            .finally(() => {
              setQuantityLoading(false);
            });
        }
      };
      
      
      

    function removeFromCartMain(product) {
      removeFromCart(product, userData, showToast).then(res => {        
        let remainingCartData = miniCartData.filter(e => e.id != product.id) 
        setMiniCartData(remainingCartData);  
        dispatch(setCartData(remainingCartData)) 
        toggleDeletePopup(null)
        dispatch(setProductWhichIsRemoved(product))
        if (remainingCartData && remainingCartData?.length == 0) {
            toggleCartMenu()
        }
      })
    }

    // const removeFromCart = (product) => {
    //     setMiniCartData(miniCartData.filter(item => item.id !== product.id));
    //     setCurrentCartCount(currentCartCount - 1);
    //     toggleDeletePopup(product);
    //     if (currentCartCount === 1) {
    //         setCartMenu(false);
    //     }
    // };

    function doSetupPopupType(status, type = null) {
        dispatch(setPopupType(type));
        dispatch(setPopupStatus(status));
      }
    

    const toggleCartMenu = () => {
        setCartMenu(!cartMenu);
        cartMenu == true ? document.body.classList.remove("sm-body-scroll") :  document.body.classList.add("sm-body-scroll")
    };

    const proceedToCheckout = () => {
        document.body.classList.remove("sm-body-scroll")
      if (userData?.id) {
        setCartMenu(!cartMenu);
      } else {
        doSetupPopupType(true, "guestLogin");
        toggleCartMenu()
      }
    };

    const toggleDeletePopup = (item) => {
        setSelectedItem(item);
        setDisplayDeletePopup(!displayDeletePopup);
        document.body.style.overflow = displayDeletePopup ? "auto" : "hidden";
    };
    return (
        <div className="relative">
            {miniCartData.length > 0 && (
                <span className="absolute -top-2 z-[5] leading-tight -end-2 size-5 flex items-center justify-center text-xs text-white bg-theme-accentColorSecondary rounded-full">
                    <span className='text-[10px] lg:text-xs font-customMedium'>{miniCartData.length}</span>
                </span>
            )}
            <button type="button" className={`${miniCartData.length >= 1 ? "" : "pointer-events-none"} relative rounded-full bg-white border border-gray-300 w-8 h-8 flex items-center justify-center`} onClick={toggleCartMenu}>
                <img src="/assets/icons/shopping-bag.svg" alt="Cart" className="w-3.5" />
            </button>

            {cartMenu && (
                <div className="fixed sm:absolute top-12 shadow-[0px_1px_10px_rgba(0,0,0,0.25)] end-[3%] md:end-0 w-[92%] sm:w-80 bg-white rounded-lg transition-opacity z-50">
                    <div className="max-h-vh70 flex flex-col overflow-y-auto">
                        <div className='flex justify-between items-center gap-2 p-4 border-b border-theme-borderColor'>
                        <p className="text-base font-customBold text-black">
                                  Cart: {miniCartData.length} {miniCartData.length === 1 ? 'item' : 'items'}
                           </p>

                            <span onClick={toggleCartMenu} className='size-6 rounded-full flex justify-center shadow-[0px_1px_10px_rgba(0,0,0,0.25)] items-center bg-white cursor-pointer'>
                                <img src="/assets/icons/close-gray.svg" alt="" className='size-2' />
                            </span>
                        </div>

                        <ul className="p-4 flex-1 overflow-y-auto scroll-style flex flex-col gap-4 ">
                            {miniCartData.map(item => (
                                <li key={item.id} className="flex  last:border-b-0 last:pb-0  gap-3 pb-4 border-b border-theme-borderColor">
                                    <div className=" w-[4.5rem] min-w-[4.5rem] lg:w-[4.5rem] lg:min-w-[4.5rem] aspect-[1/1] h-fit flex justify-center items-center bg-[#F9F9F9] rounded-lg">
                                        <img src={item.image} alt="" className="w-full h-full object-cover rounded-lg bg-[#F9F9F9]" />
                                    </div>
                                    <div className='w-full gap-2 flex flex-col justify-between'>
                                        <div className='flex justify-between items-start gap-2'>
                                            <div>
                                                <h5 className="text-theme-headingColor text-ellipsis line-clamp-2 text-sm font-customMedium">{item.name}</h5>
                                                {/* <p className="text-theme-subHeadingColor text-ellipsis line-clamp-1 text-xs font-customRegular" dangerouslySetInnerHTML={{ __html: item.description }}></p> */}
                                            </div>
                                            <span onClick={() => toggleDeletePopup(item)} className='min-w-fit cursor-pointer'>
                                                <img src="/assets/icons/Delete icon.svg" alt="" className='size-3 mt-1' />
                                            </span>
                                        </div>
                                        <div className='flex justify-between items-end gap-2'>
                                            <div>
                                                <h5 className="text-theme-headingColor text-sm font-customBold">₹ {formatPrice(item.final_price)}</h5>
                                            </div>
                                            <span className='min-w-fit flex gap-2 items-center border border-theme-borderColor px-2 py-1 rounded-lg'>
                                                <span onClick={() => handleQuantityChange(item, -1)} className={`cursor-pointer ${item.quantity === 1 ? 'pointer-events-none opacity-50' : ''}`}>
                                                    <img src="/assets/icons/minus.svg" alt="" className='size-3 brightness-0'/>
                                                </span>
                                                <span className='px-2 text-xs font-customSemiBold text-black'>{item.quantity}</span>
                                                <span onClick={() => handleQuantityChange(item, 1)} className={`cursor-pointer ${item.quantity >= Number(item.remaining_quantity) ? 'pointer-events-none opacity-50' : ''}`}>
                                                    <img src="/assets/icons/plus.svg" alt="" className='size-3 brightness-0'/>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className='p-4 flex flex-col gap-3 border-t border-theme-borderColor'>
                            <div className='flex justify-between items-center gap-3'>
                                <div className='flex items-center gap-1'>
                                    <p className='text-black text-sm font-customSemiBold'>Total:</p>
                                    <p className="text-base font-customBold text-black">
                                  {miniCartData.length} {miniCartData.length === 1 ? 'item' : 'items'}
                           </p>
                                </div>
                                <p className='text-black text-sm font-customBold'>₹ {currentCartTotal}</p>
                            </div>
                            <Link to={userData?.id ? "/checkout" : undefined}>
                                <button onClick={() => proceedToCheckout() }
                                    className="w-full py-2.5 font-customSemiBold bg-theme-accentColorSecondary text-white rounded-lg text-sm">
                                    Proceed to Checkout
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete confirmation popup */}
            {displayDeletePopup && (
                <div className="popup-css z-[52] max-w-[350px]">
                    <span onClick={() => toggleDeletePopup(null)} className="closeBtn bg-theme-accentColorSecondary text-white"> <img src="/assets/icons/close-white.svg" alt="" className='size-3' /></span>
                    <div className="bg-white p-6 rounded-md text-center">
                        <p className="text-gray-600 mb-4">Are you sure you want to delete this Item?</p>
                        <div className="flex gap-4 justify-center">
                            <button onClick={() => toggleDeletePopup(null)} className="w-full py-2.5 font-customSemiBold border border-theme-accentColorSecondary bg-white text-theme-accentColor rounded-lg text-sm">
                                Cancel
                            </button>
                            <button onClick={() => removeFromCartMain(selectedItem)} className="w-full py-2.5 font-customSemiBold bg-theme-accentColorSecondary text-white rounded-lg text-sm">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {displayDeletePopup && (
                <div onClick={() => toggleDeletePopup(null)} className='bg-overly z-[51]'> </div>
            )}
            {cartMenu && (
                <div onClick={toggleCartMenu} className='bg-overly z-[40] block lg:backdrop-blur-none lg:bg-transparent'> </div>
            )}
        </div>
    );
};

export default MiniCart