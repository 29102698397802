import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupType, setPopupStatus } from '../redux/slices/popupModal';

const Modal = ({ children,showclosebutton= true }) => {

  const isOpen = useSelector((state) => state.popupModal.status);
  const dispatch = useDispatch();
  function doSetupPopupType(status, type = null) {
    dispatch(setPopupType(type));
    dispatch(setPopupStatus(status));
  }


  const modalRef = useRef(null);
  const previousFocus = useRef(null);

  const trapFocus = (event) => {
    if (!modalRef.current.contains(event.target)) {
      modalRef.current.focus();
    }
  };

  const handleEscape = useCallback(
    (event) => {
      if (event.key === "Escape") {
        doSetupPopupType(false);
      }},[isOpen]
  );

  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      previousFocus.current = document.activeElement;
      modalRef.current.focus();
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("focusin", trapFocus);
      body.classList.add("no-scroll");
    } else {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("focusin", trapFocus);
      body.classList.remove("no-scroll");
      if (previousFocus.current) previousFocus.current.focus();
    }
    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("focusin", trapFocus);
      body.classList.remove("no-scroll");
    };
  }, [isOpen, handleEscape]);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      doSetupPopupType(false);
    }
  };

  // document.body.style.overflow = isOpen ? "hidden" : "auto";

  if (!isOpen) return null;

//   ReactDOM.createPortal is used in React to render a component (or a part of a component)
//   into a different part of the DOM tree that is outside of its parent component hierarchy
  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1028]" onClick={handleOverlayClick}>
      <div
        className="rounded-lg outline-none w-full flex items-center justify-center"
        role="dialog"
        aria-modal="true"
        ref={modalRef}
        tabIndex="-1"
      >
        
        <div className='w-fit h-fit relative rounded-lg min-w-[92vw] max-w-[92vw] xs:min-w-[unset] mx-auto '>
          
          {showclosebutton && (<span onClick={() => doSetupPopupType(false)} className='closeBtn bg-theme-accentColorSecondary'>
              <img src="/assets/icons/close-white.svg" alt="" className='size-2.5' />
          </span>)}

          {children}
        </div>
        
      </div>
    </div>,
    document.body
  );
};

export default Modal;
