import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: false,  // open or close popUpModal
  type: null, // address, register, login...
  data: null, 
};

const popupModal = createSlice({
  name: 'popupModal',
  initialState,
  reducers: {
    setPopupStatus: (state, action) => {
      if(!action.payload){
        state.type = null
      }
      state.status = action.payload;
    },
    setPopupType: (state, action) => {
      state.type = action.payload;
    },
    setPopupData: (state, action) => {
      state.data = action.payload;
    },
    closePopup: (state) => {
      state.status = false;
      state.type = null;
      state.data = null;
    },
  },
});

export const { setPopupType, setPopupStatus, setPopupData, closePopup   } = popupModal.actions;
export default popupModal.reducer;