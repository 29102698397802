import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { guestSliderConfig } from '../constants/CarouselConfigs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import apiService from '../utility-functions/Api-Services';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPopupStatus, setPopupType } from '../redux/slices/popupModal';
import useCustomToast from '../hooks/useCustomToast';
import { setUserData } from '../redux/slices/userDataSlice';
import { getCartItems, handleOfflineToOnline } from '../utility-functions/addToCartHelper';
import { setCartData } from '../redux/slices/updateCurrentCartDataSlice';
import { useNavigate } from 'react-router-dom';

function GuestLogin() {
    const { showToast} = useCustomToast();
    const { control, register, handleSubmit, formState: { errors }, reset } = useForm();
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const currentCartData = useSelector((state) => state.cartData.data, shallowEqual);
    function doSetupPopupType(status, type = null) {
        dispatch(setPopupType(type));
        dispatch(setPopupStatus(status));
    }
    const navigate = useNavigate();

    const countryCodes = [
        { code: "+91", flag: "/assets/icons/ind-flag-img.svg", country: "India" },
        // { code: "+1", flag: "/assets/icons/us-flag-img.svg", country: "USA" },
    ];

    const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes[0]);
    const handleCountryChange = (selectedOption) => {
        setSelectedCountryCode(selectedOption);

    };
    const formatOptionLabel = ({ code, flag, country }) => (
        <div className="flex items-center gap-1">
            <img src={flag} alt={country} className="size-4" />
            <span className='text-sm md:text-[15px]'>{code}</span>
        </div>
    );

    const handleNumericInput = (e) => {
        const input = e.target.value.replace(/\D/g, ''); // Allow only numeric input
        setFormData({ ...formData, phone: input });
    };

    // const handleChange = (e) => {
    //     setFormData({ ...formData, [e.target.name]: e.target.value });
    // };



    const onSubmit = async (data) => {
        try {
            console.log("Form Data:", data);
            setIsSubmitting(true);
    
            const url = "/guest-register";
            const getParams = {};
            const postParams = {
                first_name: data?.firstName,
                last_name: data?.lastName,
                phone_code: selectedCountryCode?.code.replace("+", "") || "",
                phone: data?.phone,
                device_type: "W",
                device_model: "",
                device_token: "",
                app_version: "",
                os_version: window.navigator.platform,
            };
    
            const response = await apiService.postRequest(url, getParams, postParams);
    
            if (response.status === 200) {
                showToast("success", response.message);
    
                const responseData = response.data;
    
                // Synchronous updates
                reset();
                dispatch(setUserData(responseData));
                await doOfflineToOnlineCart(responseData); // Wait for the cart update to complete
                doSetupPopupType(false);
    
                // Navigate to checkout
                window.location.pathname = "/checkout";
            } else {
                showToast("error", response.message);
            }
        } catch (err) {
            showToast("error", err.message);
            console.error("Error during form submission:", err);
        } finally {
            setIsSubmitting(false);
        }
    };
    
    const doOfflineToOnlineCart = async (userData) => {
        try {
            const res = await getCartItems(userData, showToast);
    
            if (res) {
                const { items } = res;
    
                if (items?.length === 0 && currentCartData?.length > 0) {
                    const result = await handleOfflineToOnline(currentCartData, userData, showToast);
                    if (result.items?.length > 0) {
                        dispatch(setCartData(result.items));
                    }
                }
    
                if (items?.length > 0 && currentCartData?.length > 0) {
                    const updatedCartData = [...currentCartData, ...items];
                    const result = await handleOfflineToOnline(updatedCartData, userData, showToast);
                    if (result.items?.length > 0) {
                        dispatch(setCartData(result.items));
                    }
                }
    
                if (items?.length > 0 && currentCartData?.length === 0) {
                    dispatch(setCartData(items));
                }
            }
        } catch (err) {
            console.error("Error in doOfflineToOnlineCart:", err);
        }
    };
    

    const errorMsgClass = 'text-red-500 text-sm';

    const images = [
        {
            id: 1,
            image: "/assets/images/login-banner.svg",
        },
        {
            id: 2,
            image: "/assets/images/login-banner-2.svg",
        },
        {
            id: 3,
            image: "/assets/images/login-banner.svg",
        },
        {
            id: 4,
            image: "/assets/images/login-banner-2.svg",
        }
    ]

    return (

        <div className="grid relative grid-cols-1 max-h-[calc(90vh-16px)] items-center lg:grid-cols-2 bg-white rounded-xl shadow-lg border overflow-hidden w-full  xs:max-w-[450px] lg:max-w-[1100px] mx-auto p-6 lg:p-3">
            <div className="hidden lg:block max-h-[calc(90vh-40px)] w-full min-h-[75vh] h-full bg-cover rounded-xl">
                <Swiper
                    {...guestSliderConfig}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    loop={true}
                    modules={[Navigation, Pagination, Controller, Autoplay]}
                    className='h-full'>
                    {images.map((item) => (
                        <SwiperSlide key={item.id}>
                            <img src={item.image} alt="" className='w-full h-full object-cover rounded-xl' />
                        </SwiperSlide>
                    ))}
                </Swiper>

            </div>

            <div className='w-full relative mt-6 mb-16 lg:px-12 max-h-[calc(90vh-124px)] h-fit overflow-auto lg:pt-8 lg:pb-2 lg:w-11/12 mx-auto flex flex-col justify-between gap-8'>
                <div className="text-center">
                    <p className="text-2xl md:text-3xl text-theme-headingColor pb-1 font-customBold">
                        Guest Login
                    </p>
                    <p className="text-theme-subHeadingColor text-sm font-customMedium">
                        Access our platform with basic details and create an account later to unlock more features.
                    </p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-1 overflow-y-auto scroll-style flex-col gap-8">
                    <div className='grid grid-cols-1 gap-5'>


                        <div>
                            <input
                                type="text"
                                placeholder='First name'
                                {...register('firstName', {
                                    required: "Please enter your first name"
                                })}
                                className='input-box'
                                value={formData.firstName}
                            />
                            {errors.firstName && (
                                <small className={errorMsgClass}>{errors.firstName.message}</small>
                            )}
                        </div>

                        <div>
                            <input
                                type="text"
                                placeholder='Last name'
                                {...register('lastName', {
                                    required: "Please enter your last name"
                                })}
                                className='input-box'
                                value={formData.lastName}

                            />
                            {errors.lastName && (
                                <small className={errorMsgClass}>{errors.lastName.message}</small>
                            )}
                        </div>

                        <div>

                            <div className='input-box col-span-2 lg:col-span-1 p-0 items-center flex'>
                                <Select
                                    isDisabled={true}
                                    value={selectedCountryCode}
                                    onChange={handleCountryChange}
                                    options={countryCodes}
                                    formatOptionLabel={formatOptionLabel}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            border: "none",
                                            width: "100%",
                                            opacity: 1,
                                            backgroundColor: state.isDisabled ? "white" : provided.backgroundColor,
                                        }),
                                        indicatorsContainer: (provided, state) => ({
                                            ...provided,
                                            display: state.isDisabled ? "none" : provided.display
                                        })
                                    }}
                                    className="country-select disable-select text-theme-headingColor cursor-not-allowed px-2 pe-4 w-full max-w-[75px] text-sm outline-none !border-none"
                                />
                                <div className="h-[22px] w-[2px] rounded bg-[#dcdcdc]"></div>
                                <input type="tel" placeholder="Phone Number" onInput={handleNumericInput} maxLength={10}
                                    {...register("phone", {
                                        required: "Phone number is required",
                                        minLength: { value: 10, message: "Phone number must be at least 10 digits" },
                                        maxLength: { value: 10, message: "Phone number must be at most 10 digits" }
                                    })} className="input-box rounded-s-none px-3 border-0 focus:outline-none"
                                    value={formData.phone} />
                            </div>
                            {errors.phone && (
                                <small className={errorMsgClass}>{errors.phone.message}</small>
                            )}
                        </div>
                    </div>

                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="theme-button py-3 bg-theme-accentColorSecondary border-theme-accentColorSecondary">
                        <span className={`button-text duration-300 ease-in-out`}>
                            {isSubmitting ? 'Loading...' : 'Next'}
                        </span>
                    </button>
                </form >
            </div >
            <div className='text-center w-full absolute bottom-6 lg:bottom-3 grid grid-cols-1 lg:grid-cols-2'>
                <p className='text-sm font-customMedium lg:col-start-2 px-6 lg:px-12 md:w-4/5 xl:w-3/4 mx-auto text-theme-subHeadingColor'>
                    {/* Display new message */}
                    If you are already a member, please <span onClick={() => doSetupPopupType(true, "login")} className="text-theme-accentColorSecondary font-customSemiBold underline cursor-pointer">Login</span>.
                </p>
            </div>
        </div >
    );
}

export default GuestLogin;
