// Header.js
import React, { useState, useEffect } from 'react';
import { json, Link, NavLink } from 'react-router-dom';
import Modal from '../components/Modal';
import Login from '../components/Login';
import Register from '../components/Register';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ForgotPassword from '../components/ForgotPassword';
import OTPVerification from '../components/Otp';
import MiniCart from './MiniCart';
import { setPopupStatus, setPopupType } from '../redux/slices/popupModal';
import Search from './Search';
import useNameInitials from '../hooks/useNameInitials';
import branch, { link } from 'branch-sdk'; // Import Branch SDK (if necessary)
import TermsAndConditions from '../components/terms';
import DataDeletion from '../components/datadeletion';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../utility-functions/formatters';
import GuestLogin from '../components/guestLogin';

const Header = () => {
  const navigate = useNavigate();
  const [referCode,setReferCode]=useState('')
  const { convertToInitials } = useNameInitials();

  const userData = useSelector((state) => state?.userData?.data, shallowEqual);
  const walletData = useSelector((state) => state?.userData?.walletData, shallowEqual);

  const name = convertToInitials(
    userData?.first_name, userData?.last_name
  ); 
  const dispatch = useDispatch();

  function doSetupPopupType(status, type = null) {
    dispatch(setPopupType(type));
    dispatch(setPopupStatus(status));
  }

  const currentLoginFlowPopupType = useSelector(
    (state) => state.popupModal.type
  );

  //branchio code ....
  useEffect(() => {
    const hostName = window.location.hostname;
    const branchKeys = hostName.includes('localhost') || hostName.includes('web.ad-bis.com')
      ? process.env.REACT_APP_BRANCH_IO_DEV : process.env.REACT_APP_BRANCH_IO_LIVE;
    branch.init(branchKeys, function (err, data) { });
    readDeepLink();
  }, [])

  function readDeepLink() {
    registerBranch();
    let options = { no_journeys: true };
    branch.data((err, data) => {     
      if(data){        
        if ((data?.data_parsed.target != '' && data.data_parsed.target != undefined) && (data.data_parsed.target_id != '' && data.data_parsed.target_id != undefined)) {
          if (data.data_parsed.target == 'RE') {
            const referenceId = data.data_parsed.target_id
            setReferCode(data.data_parsed.target_id)
            if(referenceId!==''){
              doSetupPopupType(true, "register")
            }
          }else if (data.data_parsed.target=='P'){
            const prodRefCode=data.data_parsed.secondary_id!='' ? data.data_parsed.secondary_id: '';
            sessionStorage.setItem('prodRefCode',prodRefCode)
            navigate(`/product-details/${data.data_parsed.target_id}`);
          }
        }
      }
    });
  }

  // to register branch io
  function registerBranch() {
    registerBranchSource(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
  }
  // to register branch source
  function registerBranchSource(b, r, a, n, c, h, _, s) {
    if (!b[n] || !b[n]._q) {
      for (; s < _.length;)
        c(h, _[s++]);
      let d = r.createElement(a);
      d.async = 1;
      d.src = "https://cdn.branch.io/branch-latest.min.js";
      let k = r.getElementsByTagName(a)[0];
      k?.parentNode.insertBefore(d, k);
      b[n] = h;
    }
  }

  useEffect(() => {
    if (currentLoginFlowPopupType) {
      const popupHistory = JSON.parse(sessionStorage.getItem('popupHistory') || '[]');
      if (popupHistory[popupHistory.length - 1] !== currentLoginFlowPopupType) {
        popupHistory.push(currentLoginFlowPopupType);
        sessionStorage.setItem('popupHistory', JSON.stringify(popupHistory));
      }
      sessionStorage.setItem('currentPopupType', currentLoginFlowPopupType);
    }
  }, [currentLoginFlowPopupType]);
  
  
  return (
    <>
      <header className="text-theme-headingColor sticky top-0 w-full inset-x-0 bg-white z-[1024]  shadow py-2 px-5 md:px-8 xl:px-20">
        <div className="mx-auto justify-between items-center  flex">
          <div className="flex items-center gap-5 lg:gap-8">
            <Link to="/">
              <div className="size-14">
                <img
                  src="/assets/icons/adbis-logo.svg"
                  alt="adbis-logo"
                  className="size-14"
                />
              </div>
            </Link>
            <nav className="hidden md:flex items-center">
              <ul className="flex items-center gap-5 lg:gap-8">
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-theme-accentColorSecondary"
                        : "text-theme-headingColor") +
                      " font-customMedium flex items-center gap-1 text-[13px] lg:text-[15px]"
                    }
                  >
                    Home
                  </NavLink>
                </li>
                {userData?.is_active_subscription === 1 && (<li>
                  <NavLink
                    to="/wallet"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-theme-accentColorSecondary"
                        : "text-theme-headingColor") +
                      " font-customMedium flex items-center gap-1 text-[13px] lg:text-[15px]"
                    }
                  >
                    <span> Wallet </span>
                    {walletData && walletData?.wallet_balance > 0 && (
                      <>(Rs. {formatPrice(walletData.wallet_balance)})</>
                    )}
                  </NavLink>
                </li>)}

                <li>
                  <NavLink
                    to="/listing"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-theme-accentColorSecondary"
                        : "text-theme-headingColor") +
                      " font-customMedium flex items-center gap-1 text-[13px] lg:text-[15px]"
                    }
                  >
                    Products
                  </NavLink>
                </li>
                {userData?.is_active_subscription === 1 && (
                <li>
                  <NavLink
                    to="/referral"
                    className={({ isActive }) =>
                      (isActive
                        ? "text-theme-accentColorSecondary"
                        : "text-theme-headingColor") +
                      " font-customMedium flex items-center gap-1 text-[13px] lg:text-[15px]"
                    }
                  >
                    Referral
                  </NavLink>
                </li>
                )}
              </ul>
            </nav>
          </div>
          <nav>
            <ul className="flex gap-3 items-center">
              {userData.id && userData?.is_active_subscription === 1 && (
                 <li className='hidden md:flex'>
                  <Link to="/wallet">
                    <div className='rounded-lg border border-theme-borderColor p-1.5 px-3' >
                      <p className='text-theme-subHeadingColor font-customMedium text-[13px] lg:text-[15px]'>Total Earnings: <span className='font-customSemiBold text-black'>Rs. {formatPrice(walletData.total_earnings) || 0}</span></p>
                    </div>
                    </Link>
                  </li>
                )} 

              <li>
                <div className="border border-theme-borderColor size-7 lg:size-8  rounded-full flex justify-center items-center">
                  <Search />
                </div>
              </li>
              <li>
                <div className="border border-theme-borderColor size-7 lg:size-8  rounded-full flex justify-center items-center">
                  <MiniCart />
                </div>
              </li>
              <li className="hidden md:flex">
                {userData?.id ? (
                  <Link to="/my-account/edit-profile">
                    {userData?.image ? (
                      <img
                        src={userData.image}
                        alt="Profile"
                        className="size-7 lg:size-8 rounded-full border border-theme-borderColor"
                        onError={(e) => {
                          e.target.src = "/assets/temp-img/profile-image.svg";
                        }}
                      />
                    ) : (
                      <div className="bg-theme-accentColorSecondary size-7 lg:size-8 rounded-full flex justify-center items-center cursor-pointer">
                        <p className="text-white pb-0.5 text-xs lg:text-[13px]">
                          {name}
                        </p>
                      </div>
                    )}
                  </Link>
                ) : (
                  <div
                    onClick={() => doSetupPopupType(true, "login")}
                    className="border cursor-pointer border-theme-borderColor size-7 lg:size-8  rounded-full flex justify-center items-center"
                  >
                    <img
                      src="/assets/icons/user.svg"
                      alt=""
                      className="size-4"
                    />
                  </div>
                )}
              </li>
            </ul>
          </nav>
        </div>

        {/* <div className='flex justify-between items-center md:hidden'>
          <Link to="/">
            <div className="size-14">
              <img src="/assets/icons/adbis-logo.svg" alt="adbis-logo" />
            </div>
          </Link>

          <div>
            <div className="border border-theme-borderColor size-7 lg:size-8  rounded-full flex justify-center items-center">
              <MiniCart />
            </div>
          </div>
        </div> */}

        <ul className="px-5 pt-2.5 pb-3.5 shadow-[0px_-1px_10px_rgba(0,0,0,0.15)] bg-white w-full fixed gap-1 inset-x-0 -bottom-1 flex justify-between items-center md:hidden">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                (isActive
                  ? "text-theme-accentColorSecondary active-route"
                  : "text-[#99A1AC]") +
                " font-customMedium duration-300 ease-in-out text-[12px] lg:text-[15px] flex flex-col items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/home-icon.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/wallet"
              className={({ isActive }) =>
                (isActive
                  ? "text-theme-accentColorSecondary active-route"
                  : "text-[#99A1AC]") +
                " font-customMedium duration-300 ease-in-out text-[12px] lg:text-[15px] flex flex-col items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/wallet-gray.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Wallet
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/listing"
              className={({ isActive }) =>
                (isActive
                  ? "text-theme-accentColorSecondary active-route"
                  : "text-[#99A1AC]") +
                " font-customMedium duration-300 ease-in-out text-[12px] lg:text-[15px] flex flex-col items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/product-gray.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Products
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/referral"
              className={({ isActive }) =>
                (isActive
                  ? "text-theme-accentColorSecondary active-route"
                  : "text-[#99A1AC]") +
                " font-customMedium duration-300 ease-in-out text-[12px] lg:text-[15px] flex flex-col items-center gap-0.5"
              }
            >
              <img
                src="/assets/icons/referral.svg"
                alt=""
                className="size-5 duration-300 ease-in-out"
              />
              Referral
            </NavLink>
          </li>
          <li>
            {userData?.id ? (
              <NavLink
                to="/my-account/sidebar"
                className={
                  "text-[#99A1AC] font-customMedium text-[12px] lg:text-[15px] flex flex-col duration-300 ease-in-out items-center gap-0.5"
                }
              >
                <img
                  src="/assets/icons/account-gray.svg"
                  alt=""
                  className="size-5 duration-300 ease-in-out"
                />
                Account
              </NavLink>
            ) : (
              <NavLink
                onClick={() => doSetupPopupType(true, "login")}
                className={
                  "text-[#99A1AC] font-customMedium text-[12px] lg:text-[15px] flex flex-col duration-300 ease-in-out items-center gap-0.5"
                }
              >
                <img
                  src="/assets/icons/account-gray.svg"
                  alt=""
                  className="size-5 duration-300 ease-in-out"
                />
                Account
              </NavLink>
            )}
          </li>
        </ul>
      </header>

      {currentLoginFlowPopupType === "login" && (
        <Modal>
          {" "}
          <Login />{" "}
        </Modal>
      )}
      {currentLoginFlowPopupType === "register" && (
        <Modal>
          {" "}
          <Register referCode={referCode}/>{" "}
        </Modal>
      )}
      {currentLoginFlowPopupType === "forgotPassword" && (
        <Modal>
          {" "}
          <ForgotPassword />{" "}
        </Modal>
      )}
      {currentLoginFlowPopupType === "otpVerification" && (
        <Modal>
          {" "}
          <OTPVerification />{" "}
        </Modal>
      )}
      {currentLoginFlowPopupType === "term" && (
        <Modal showclosebutton={false}>
          {" "}
          <TermsAndConditions />{" "}
        </Modal>
      )}
      {currentLoginFlowPopupType === "dataDeletion" && (
        <Modal showclosebutton={false}>
          {" "}
          <DataDeletion />{" "}
        </Modal>
      )}

      {currentLoginFlowPopupType === "guestLogin" && (
        <Modal >
          <GuestLogin />{" "}
        </Modal>
      )}
    </>
  );
};

export default Header;
