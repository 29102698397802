import React, { useState, useEffect } from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { setPopupStatus, setPopupType, setPopupData } from '../redux/slices/popupModal';
import useCmsData from '../redux/slices/useCmsdata';

const DataDeletion = () => {
    const dispatch = useDispatch();

    function doSetupPopupType(status, type = null) {
      dispatch(setPopupType(type));
      dispatch(setPopupStatus(status));
    }
    const currentLoginFlowPopupType = useSelector(
      (state) => state.popupModal.type
    );
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const closePopup = () => {
    const popupHistory = JSON.parse(sessionStorage.getItem('popupHistory') || '[]');
    
    if (popupHistory.length > 1) {
      popupHistory.pop();
      const previousPopupType = popupHistory[popupHistory.length - 1];
      sessionStorage.setItem('popupHistory', JSON.stringify(popupHistory));
      dispatch(setPopupType(previousPopupType));
      dispatch(setPopupStatus(true));
    } else {
      sessionStorage.removeItem('popupHistory');
      sessionStorage.removeItem('currentPopupType');
      dispatch(setPopupStatus(false));
    }
  };
  const [cmsData, setCmsData] = useState({ cms_title: '', cms_description: '' });
  const pageId = 4;

  const { cms_title, cms_description } = useCmsData(pageId);

  useEffect(() => {
    if (cms_title && cms_description) {
      setCmsData({ cms_title, cms_description });
    }
  }, [cms_title, cms_description]);


  return (
    <div>
      {isPopupOpen && <div onClick={closePopup} className="bg-overly"></div>}
      <div className="popup-css max-w-[80%] sm:max-w-[75%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] w-full">
        <span
          onClick={closePopup}
          className="closeBtn bg-theme-accentColorSecondary"
        >
          <img
            src="/assets/icons/close-white.svg"
            alt=""
            className="size-2.5"
          />
        </span>
        <div className="popup-box scroll-style rounded-xl overflow-auto min-h-[600px] flex flex-col items-center">
          {cms_title ? (
             <div className="flex items-center justify-between w-full relative mb-6 pt-6 pb-6">
             <img
               onClick={() => closePopup()}
               src="/assets/icons/left-arrow.svg"
               alt="Back"
               className="size-6 cursor-pointer absolute sm:mr-36"
             />
             <h2 className="text-2xl text-theme-headingColor font-customBold flex-grow text-center ml-7">
               {cms_title}
             </h2>
           </div>
           
           
          ) : (
            <h2 className="text-xl font-bold text-theme-subHeadingColor text-center mb-6">
              Loading...
            </h2>
          )}
          <div className="text-theme-subHeadingColor w-full text-start">
            {cms_description ? (
              <div
                className="cms-content pl-4"
                dangerouslySetInnerHTML={{ __html: cms_description }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataDeletion;
